//
//
//  Team Settings Members
//
//

import {Flex, rem, Title, Button, Modal, Group, Text, Badge, TextInput} from "@mantine/core";
import { IconCirclePlus } from "@tabler/icons-react";
import {Team, User} from "../interfaces.ts";
import {useEffect, useRef, useState} from "react";
import {onResourceDelete} from "../utils.ts";
import { isEmail } from "@mantine/form";
import {DataTable} from "mantine-datatable";
import {useAutoAnimate} from "@formkit/auto-animate/react";
import {useApiErrorHandler} from "../hooks.ts";
import classes from "./TeamSettingsMembers.module.css"
import Api from "../api.ts";
import {useOutletContext} from "react-router-dom";
import {modals} from "@mantine/modals";
import {notifications} from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useForm } from "@mantine/form";


interface FormValues {
    email: string
}

function TeamSettingsMembers({pageSize=10}: {pageSize?: number}) {
    const { t } = useTranslation()
    const [team]: [Team] = useOutletContext()
    const handleError = useApiErrorHandler()
    const membersRef = useRef<User[]>([])
    const [inviteLoading, setInviteLoading] = useState(false)
    const [bodyRef] = useAutoAnimate<HTMLTableSectionElement>()
    const [loading, setLoading] = useState(true)
    const [members, setMembers] = useState<User[]>([])
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [totalMembers, setTotalMembers] = useState(0)
    const [addMemberOpen, setAddMemberOpen] = useState(false)
    const [page, setPage] = useState(1)

    const emailForm = useForm<FormValues>({
        initialValues: {
          email: ""
        },
        validate: {
          email: isEmail('Invalid email'),
      },
      });

    useEffect(() => {
        setLoading(true)
        Api.getTeamMembers(team.id, page, pageSize)
            .then(response => {
                membersRef.current = response["items"]
                setMembers(response["items"])
                setTotalMembers(response["total"])
            }).catch((err: any) => {
                console.error(err)
                handleError(err)
            }).finally(() => {
                setLoading(false)
            })
    }, [page])

    function deleteMember(member: User) {
        modals.openConfirmModal({
            title: t("Delete member"),
            centered: true,
            children: (
                <Text size="sm">
                    {t("Are you sure you want to remove")} {member.name} ({member.email})?
                    <br/>
                </Text>
            ),
            labels: { confirm: t("Remove"), cancel: t("No, don't remove it") },
            confirmProps: { color: 'red' },
            onConfirm: () => {
                setDeleteLoading(true)
                Api.deleteTeamMember(team.id, member.id)
                    .then(() => {
                        membersRef.current = onResourceDelete(member, membersRef.current)
                        setMembers(membersRef.current)
                        setTotalMembers(totalMembers - 1)
                    }).catch((err: Error) => {
                        console.error(err)
                        handleError(err)
                    }).finally(() => {
                        setDeleteLoading(false)
                    })
            }
        })
    }

    function renderMember(member: User) {
        const isOwner = team.created_by.id === member.id

        return (
            <>
                <div className={classes.member}>
                    <div className={classes.memberDescription}>
                        <Flex align="center" gap={8}>
                            <Title size="h4">{member.name}</Title>
                            {isOwner &&
                                <Badge variant="outline" color="indigo">Owner</Badge>
                            }
                        </Flex>
                        <Text c="dimmed">{member.email}</Text>
                    </div>
                    <div className={classes.memberActions}>
                        {!isOwner &&
                            <Button loading={deleteLoading} variant="outline" color="red" onClick={() => deleteMember(member)}>{t("Remove")}</Button>
                        }
                    </div>
                </div>
            </>
        )
    }

    async function addMember(values: any) {
        setInviteLoading(true)
            await Api.createTeamInvitation(team.id, values['email'])
            .then(() => {
                    setAddMemberOpen(false)
                    notifications.show({
                        title: t("Invitation sent"),
                        message: t("Invitations sent to the specified email."),
                        color: "green"
                    })
                }).catch(err => {
                    console.error(err)
                    handleError(err)
                }).finally(() => {
                    setInviteLoading(false)
                    emailForm.reset()
                })
        
    }

    function onCloseInviteMembers() {
        setAddMemberOpen(false)
        emailForm.reset()
    }

    return (
        <>
            <Flex
                justify="space-between"
                align="center"
                direction="row"
                mt={rem(50)}
                mb={rem(30)}
            >
                <Title size="h1">{t("Members")}</Title>
                <Button
                    onClick={() => setAddMemberOpen(true)}
                    leftSection={<IconCirclePlus size={16} />}
                >
                    {t("Invite a new member")}
                </Button>
            </Flex>
            
            <Modal opened={addMemberOpen} onClose={onCloseInviteMembers} title={t("Add a new member to the team")}>
                <form onSubmit={emailForm.onSubmit(addMember)}>
                <TextInput
                  label={t("Email")}
                  placeholder={t("Add an email")}
                  mt="sm"
                  name="email"
                  {...emailForm.getInputProps("email")}
                />
                <Group align="right" mt="md">
                    <Button type="submit" loading={inviteLoading} >{t("Invite")}</Button>
                </Group>
                </form>
            </Modal>
           
            <DataTable
                bodyRef={bodyRef}
                fetching={loading}
                columns={[
                    {
                        accessor: "",
                        render: renderMember
                    }
                ]}
                records={members}
                defaultColumnProps={{
                    noWrap: true,
                    ellipsis: true,
                    cellsStyle: (member: any) => {
                        if (member._isNew) {
                            return {backgroundColor: "#F5FBEF"}
                        }
                    }
                }}
                noHeader
                withRowBorders={false}
                borderColor={"white"}
                page={page}
                verticalSpacing={8}
                onPageChange={setPage}
                totalRecords={totalMembers}
                recordsPerPage={pageSize}
                minHeight={members.length === 0 ? 300 : undefined}
            />
        </>
    )
}

export default TeamSettingsMembers
