//
//
//  Intent Drawer
//
//


import {Intent, Utterance} from "../interfaces.ts"
import {
    Button,
    Drawer,
    TextInput,
    Title,
    LoadingOverlay
} from "@mantine/core"
import Api from "../api.ts";
import {onResourceAdd, onResourceDelete, onResourceUpdate} from "../utils.ts";
import {Socket} from "socket.io-client";
import {useSocket} from "../contexts/SocketContext.tsx";
import { useApiErrorHandler } from "../hooks.ts";
import { useTranslation } from "react-i18next";
import UtterancesTable from "./UtterancesTable.tsx";
import { useEffect, useRef, useState } from "react";
import { useForm } from "@mantine/form";


function ProjectIntentDrawer({intent, opened, onClose, onDelete, onUtteranceAdd, deleteLoading}:
                      {intent: Intent, opened: boolean, onClose: () => void,
                       onDelete: (intent: Intent) => void, onUtteranceAdd: (utterance: Utterance, intent: Intent) => void,
                       onUtteranceDelete: (utterance: Utterance, intent: Intent) => void, deleteLoading: boolean}) {
    const { t } = useTranslation()
    const handleError = useApiErrorHandler()
    const [loading, setLoading] = useState(true)
    const {socket}: {socket: Socket} = useSocket()
    const [updateLoading, setUpdateLoading] = useState(false)
    const utterancesRef = useRef<Utterance[]>([])
    const [utterances, setUtterances] = useState<Utterance[]>([])

    useEffect(() => {
        Api.getIntentUtterances(intent.id)
            .then(listUtterances => {
                utterancesRef.current = listUtterances
                setUtterances(listUtterances)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
    }, [intent.id])

    useEffect(() => {
        function onAdd(added: Utterance) {
            if (added.intent.id === intent.id) {
                utterancesRef.current = onResourceAdd(added, utterancesRef.current)
                setUtterances(utterancesRef.current)
            }
        }

        function onUpdate(updated: Utterance) {
            if (updated.intent.id === intent.id) {
                utterancesRef.current = onResourceUpdate(updated, utterancesRef.current)
                setUtterances(utterancesRef.current)
            }
        }

        function onDelete(deleted: Utterance) {
            if (deleted.intent.id === intent.id) {
                utterancesRef.current = onResourceDelete(deleted, utterancesRef.current)
                setUtterances(utterancesRef.current)
            }
        }

        socket.on("intent_utterance:add", onAdd)
        socket.on("intent_utterance:update", onUpdate)
        socket.on("intent_utterance:delete", onDelete)

        return () => {
            socket.off("intent_utterance:add", onAdd)
            socket.off("intent_utterance:update", onUpdate)
            socket.off("intent_utterance:delete", onDelete)
        }
    }, [intent.id, socket]);

    const updateIntentForm = useForm({
        initialValues: {
            name: intent.name
        }
    })

    function onSubmitUpdateIntent(values: any) {
        setUpdateLoading(true)
        Api.updateIntent(intent.id, values)
            .then(() => {
                setUpdateLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setUpdateLoading(false)
            })
    }

    return (
        <Drawer
            opened={opened}
            onClose={onClose}
            title={intent.name}
            position="right"
            styles={{
                content: {
                    display: "flex",
                    flexDirection: "column"
                },
                body: {
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1
                }
            }}
        >
            <form onSubmit={updateIntentForm.onSubmit(onSubmitUpdateIntent)}>
                <TextInput
                  withAsterisk
                  required
                  label={t("Name")}
                  placeholder={t("Super duper topic")}
                  {...updateIntentForm.getInputProps("name")}
                />
                <Button mt="md" type="submit" loading={updateLoading}>{t("Save")}</Button>
            </form>
            <Title size="h3" mt="lg" mb="md">{t("Sentences")}</Title>
            <LoadingOverlay visible={loading}/>
            <UtterancesTable
                intent={intent}
                utterances={utterances}
                onUtteranceAdd={onUtteranceAdd}
                onDelete={onDelete}
                deleteLoading={deleteLoading}
            />
        </Drawer>
    )
}

export default ProjectIntentDrawer
