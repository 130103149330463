import { ActionIcon, Button, Divider, Flex, Table, TextInput, UnstyledButton } from '@mantine/core'
import { useState } from 'react'
import { reverse } from '../utils'
import { IconBrandOpenai, IconCirclePlus, IconTrash } from '@tabler/icons-react'
import classes from "./ProjectIntentDrawer.module.css"
import { useTranslation } from "react-i18next";
import { Intent, Utterance } from '../interfaces'
import { useAutoAnimate } from '@formkit/auto-animate/react'
import Api from '../api'
import ProjectIntentSuggestionsModal from './ProjectIntentSuggestionsModal'
import { useForm } from '@mantine/form'
import { useApiErrorHandler } from '../hooks'

interface UtteranceForm {
    utterance: string
}

function UtterancesTable({ intent, utterances, onUtteranceAdd, onDelete, deleteLoading }: { intent: Intent, utterances: Utterance[] | null, onUtteranceAdd: (utterance: Utterance, intent: Intent) => void, onDelete: (intent: Intent) => void, deleteLoading: boolean }) {

    const { t } = useTranslation()
    const [parent] = useAutoAnimate()
    const handleError = useApiErrorHandler()
    const [suggestionsOpened, setSuggestionsOpened] = useState<boolean>(false)

    const createUtteranceform = useForm({
        initialValues: {
            utterance: "",
        },
        validate: {
            utterance: value => value.length < 1
        }
    })

    function onSubmitCreateUtterance(values: UtteranceForm) {
        Api.createIntentUtterance(intent.id, values.utterance)
            .then(utterance => {
                createUtteranceform.reset()
                onUtteranceAdd(utterance, intent)
            }).catch((err) => {
                console.error(err);
                handleError(err)
            })
    }

    function deleteUtterance(utteranceId: number) {
        Api.deleteIntentUtterance(utteranceId)
            .catch(console.error)

    }

    return (
        <>
            <form onSubmit={createUtteranceform.onSubmit(onSubmitCreateUtterance)}>
                <Flex justify="flex-end" mb={6} gap={8}>
                    <Button color="#64a092d9" onClick={() => setSuggestionsOpened(true)} leftSection={<IconBrandOpenai size={18} />} size="xs">
                        {t("Suggest")}
                    </Button>
                </Flex>
                <TextInput
                    withAsterisk
                    required
                    placeholder={t("What might a user say to invoke this topic?")}
                    {...createUtteranceform.getInputProps("utterance")}
                    rightSection={<UnstyledButton type="submit"><IconCirclePlus size={16} /></UnstyledButton>}
                />
            </form>
            <Divider my="sm" />
            <div className={classes.content}>
            {utterances && (
                <Table mt="md" withTableBorder>
                    <Table.Tbody ref={parent}>
                        {reverse(utterances).map(utterance => {
                            return (
                                <Table.Tr key={utterance.id} className={classes.formRow}>
                                    <Table.Td>
                                        {utterance.utterance}
                                    </Table.Td>
                                    <Table.Td>
                                        <ActionIcon variant="subtle" color="red" aria-label={t("Delete")} onClick={() => deleteUtterance(utterance.id)}>
                                            <IconTrash size={16} />
                                        </ActionIcon>
                                    </Table.Td>
                                </Table.Tr>
                            )
                        })}
                    </Table.Tbody>
                </Table>
                )}
                <div className={classes.footer}>
                    <Divider mt="xl" mb="xl" />
                    <Button loading={deleteLoading} color="red" leftSection={<IconTrash size={16} />} fullWidth onClick={() => onDelete(intent)}>
                        {t("Delete topic")}
                    </Button>
                </div>
            </div>
            <ProjectIntentSuggestionsModal
                intent={intent}
                utterances={utterances}
                onUtterance={(utterance: string) => onSubmitCreateUtterance({ "utterance": utterance })}
                opened={suggestionsOpened}
                onClose={() => setSuggestionsOpened(false)}
            />
        </>
    )
}

export default UtterancesTable
