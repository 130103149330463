//
//
//  Project Channel Drawer
//
//

import {DeploymentChannel, PhoneDeploymentChannel, TelegramDeploymentChannel, WhatsappDeploymentChannel} from "../interfaces.ts";
import {Drawer} from "@mantine/core";
import {useForm} from "@mantine/form";
import {Button, TextInput} from "@mantine/core";
import {useState} from "react";

import classes from "./ChannelDrawer.module.css"
import {Divider} from "@mantine/core";
import {IconTrash} from "@tabler/icons-react";
import Api from "../api.ts";


function ChannelDrawer({channel, opened, onClose, onDelete, deleteLoading}: {channel: DeploymentChannel, opened: boolean, onClose: () => void, onDelete: (channel: DeploymentChannel) => void, deleteLoading: boolean}) {
    const [updateLoading, setUpdateLoading] = useState(false)

    const phoneChannelMetadataForm = useForm({
        initialValues: {
            id: channel.id,
            name: channel.name,
            access_type: channel.access_type,
            project: channel.project,
            phone_number: (channel as PhoneDeploymentChannel).phone_number
        }
    })

    const telegramChannelMetadataForm = useForm({
        initialValues: {
            id: channel.id,
            name: channel.name,
            access_type: channel.access_type,
            project: channel.project,
            bot_name: (channel as TelegramDeploymentChannel).bot_name
        }
    })

    const alexaChannelMetadataForm = useForm({
        initialValues: {
            id: channel.id,
            name: channel.name,
            access_type: channel.access_type,
            project: channel.project
        }
    })

    const whatsappChannelMetadataForm = useForm({
        initialValues: {
            id: channel.id,
            name: channel.name,
            access_type: channel.access_type,
            project: channel.project,
            phone_number: (channel as WhatsappDeploymentChannel).phone_number,
            phone_number_id: (channel as WhatsappDeploymentChannel).phone_number_id
        }
    })

    function onUpdateChannel(values: any) {
        const data = {...values}
        setUpdateLoading(true)

        Api.updateDeploymentChannel(channel.id, data)
            .then(() => {
                setUpdateLoading(false)
            })
    }


    return (
        <Drawer
            opened={opened}
            onClose={onClose}
            position="right"
            className={classes.drawer}
            withinPortal={true}
            styles={{
                content: {
                    display: "flex",
                    flexDirection: "column"
                },
                body: {
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1
                }
            }}
        >

            {(channel.channel_type == "PHONE") &&
                <form onSubmit={phoneChannelMetadataForm.onSubmit(onUpdateChannel)}>
                    <TextInput
                        label="Name"
                        mb="md"
                        {...phoneChannelMetadataForm.getInputProps("name")}
                    />

                    <TextInput
                        label="Phone number"
                        mb="md"
                        {...phoneChannelMetadataForm.getInputProps("phone_number")}
                    />

                    <Button mt="md" type="submit" loading={updateLoading}>Save</Button>
                </form>
            }

            {(channel.channel_type == "TELEGRAM") &&
                <form onSubmit={telegramChannelMetadataForm.onSubmit(onUpdateChannel)}>
                    <TextInput
                        label="Name"
                        mb="md"
                        {...telegramChannelMetadataForm.getInputProps("name")}
                    />

                    <TextInput
                        label="Bot name"
                        mb="md"
                        {...telegramChannelMetadataForm.getInputProps("bot_name")}
                    />

                    <Button mt="md" type="submit" loading={updateLoading}>Save</Button>
                </form>
            }

            {(channel.channel_type == "ALEXA") &&
                <form onSubmit={alexaChannelMetadataForm.onSubmit(onUpdateChannel)}>
                    <TextInput
                        label="Name"
                        mb="md"
                        {...alexaChannelMetadataForm.getInputProps("name")}
                    />

                    <Button mt="md" type="submit" loading={updateLoading}>Save</Button>
                </form>
            }

            {(channel.channel_type == "WHATSAPP") &&
                <form onSubmit={whatsappChannelMetadataForm.onSubmit(onUpdateChannel)}>
                    <TextInput
                        label="Name"
                        mb="md"
                        {...whatsappChannelMetadataForm.getInputProps("name")}
                    />

                    <TextInput
                        label="Phone number"
                        mb="md"
                        {...whatsappChannelMetadataForm.getInputProps("phone_number")}
                    />

                    <TextInput
                        label="Phone number ID"
                        mb="md"
                        {...whatsappChannelMetadataForm.getInputProps("phone_number_id")}
                    />

                    <Button mt="md" type="submit" loading={updateLoading}>Save</Button>
                </form>
            }
            
            <div className={classes.content}>
                <div className={classes.footer}>
                    <Divider mt="xl" mb="xl"/>
                    <Button loading={deleteLoading} color="red" leftSection={<IconTrash size={16}/>} fullWidth onClick={() => onDelete(channel)}>
                        Delete deployment
                    </Button>
                </div>
            </div>
        </Drawer>
    )
}

export default ChannelDrawer
