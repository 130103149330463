//
//
//  Channel List
//
//

import {useEffect, useRef, useState} from "react";
import {
    Button,
    Container,
    Flex,
    rem,
    Title
} from "@mantine/core";
import {Link, useNavigate} from "react-router-dom"
import Api from "../api.ts"
import {IconCirclePlus} from "@tabler/icons-react"
import ChannelsDataTable from "../components/ChannelsDataTable.tsx";
import {DeploymentChannel} from "../interfaces.ts";
import {useSocket} from "../contexts/SocketContext.tsx";
import {onResourceAdd, onResourceDelete, onResourceUpdate, sortByUpdatedAt} from "../utils.ts";
import PaginationControl from "../components/PaginationControl.tsx";
import ChannelDrawer from "../components/ChannelDrawer.tsx";

function AdminChannelList() {
    const pageLength = 15

    const navigate = useNavigate()
    const {socket} = useSocket()
    const channelsRef = useRef<DeploymentChannel[]>([])
    const [channels, setChannels] = useState<DeploymentChannel[]>([])
    const [loading, setLoading] = useState(true)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [totalChannels, setTotalChannels] = useState(0)
    const [page, setPage] = useState(1)
    const newElementsRef = useRef<DeploymentChannel[]>([])
    const [newElements, setNewElements] = useState<DeploymentChannel[]>([])
    const [selectedChannel, setSelectedChannel] = useState<DeploymentChannel | null>(null)

    function loadChannelsPage() {
        setLoading(true)
        Api.getDeploymentChannels(page, pageLength)
            .then(pageChannels => {
                channelsRef.current = pageChannels["items"]
                setChannels(sortByUpdatedAt(channelsRef.current))
                setTotalChannels(pageChannels["total"])
                setLoading(false)
            }).catch(console.error)
    }


    function onDeleteChannel(channel: DeploymentChannel) {
        setDeleteLoading(true)
        Api.deleteDeploymentChannel(channel.id)
            .then(() => {
                setDeleteLoading(false)
                setSelectedChannel(null)
                loadChannelsPage()
            })
    }


    useEffect(() => {
        newElementsRef.current = []
        setNewElements(newElementsRef.current)
        loadChannelsPage()
    }, [page])

    useEffect(() => {
        function onChannelAdd(added: DeploymentChannel) {
            channelsRef.current = onResourceAdd(added, channelsRef.current)
            newElementsRef.current = onResourceAdd(added, newElementsRef.current)
            setNewElements(newElementsRef.current)
        }

        function onChannelUpdate(updated: DeploymentChannel) {
            channelsRef.current = onResourceUpdate(updated, channelsRef.current)
            setPage(1)
            loadChannelsPage()
        }

        function onChannelDelete(deleted: DeploymentChannel) {
            channelsRef.current = onResourceDelete(deleted, channelsRef.current)
            
            newElementsRef.current.forEach(element => {
                if (element.id === deleted.id) {
                    newElementsRef.current = onResourceDelete(deleted, newElementsRef.current)
                    setNewElements(newElementsRef.current)
                }
            });
        }

        socket.on("channel:add", onChannelAdd)
        socket.on("channel:update", onChannelUpdate)
        socket.on("channel:delete", onChannelDelete)

        return () => {
            socket.off("channel:add", onChannelAdd)
            socket.off("channel:update", onChannelUpdate)
            socket.off("channel:delete", onChannelDelete)
        }
    }, [socket])

    return (
        <>
            <main>
                <Container>
                    <Flex
                        justify="space-between"
                        align="center"
                        direction="row"
                        mt={rem(50)}
                        mb={rem(30)}
                    >
                        <Title size="h1">Deployment</Title>
                        <Button
                            component={Link}
                            to="/admin/channels/create"
                            leftSection={<IconCirclePlus size={16}/>}
                        >
                            Create Deployment
                        </Button>
                    </Flex>
                    <ChannelsDataTable
                        channels={channels}
                        newChannels={newElements}
                        loading={loading}
                        onCreate={() => navigate("/admin/channels/create")}
                        onSelection={channel => setSelectedChannel(channel)}
                    />
                    <PaginationControl 
                        totalElements={totalChannels}
                        page={page}
                        pageLength={pageLength}
                        onChange={setPage}
                    />

                    {selectedChannel != null &&
                        <ChannelDrawer
                            channel={selectedChannel}
                            opened={true}
                            onClose={() => setSelectedChannel(null)}
                            deleteLoading={deleteLoading}
                            onDelete={onDeleteChannel}
                        />
                    }
                </Container>
            </main>
        </>
    )
}

export default AdminChannelList
