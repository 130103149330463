//
//
//  Create Response Modal
//
//


import { Button, Flex, Modal, TextInput, Textarea } from "@mantine/core"
import ProjectResponseAudioPlayer from "./ProjectResponseAudioPlayer"
import { useForm } from "@mantine/form"
import { useOutletContext } from "react-router-dom"
import { Project, Response } from "../interfaces"
import { useRef, useState } from "react"
import { useTranslation } from "react-i18next";
import Api from "../api"
import { useApiErrorHandler } from "../hooks"

interface ResponseForm {
    name: string
    response: string
}

function CreateResponseModal({ createResponseOpen, onCloseCreateResponse, onCreateResponse }: { createResponseOpen: boolean, onCloseCreateResponse: () => void, onCreateResponse: (response: Response) => void }) {

    const { t } = useTranslation()
    const textRef = useRef(null)
    const handleError = useApiErrorHandler()
    const [createResponseLoading, setCreateResponseLoading] = useState(false)
    const [project]: [Project] = useOutletContext()

    const createResponseForm = useForm({
        initialValues: {
            name: "",
            response: ""
        }
    })

    const textValue = (textRef.current as (HTMLTextAreaElement | null))?.value || ""
    
    function onSubmitCreateResponse(values: ResponseForm) {
        setCreateResponseLoading(true)
        Api.createResponse(project.id, values.name, values.response)
            .then(response => {
                setCreateResponseLoading(false)
                createResponseForm.reset()
                onCreateResponse(response)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setCreateResponseLoading(false)
            })
    }

    return (
        <Modal opened={createResponseOpen} onClose={onCloseCreateResponse} title={t("New Response")}>
            <form onSubmit={createResponseForm.onSubmit(onSubmitCreateResponse)}>
                <TextInput
                    withAsterisk
                    label={t("Name")}
                    required
                    placeholder={t("Super duper response")}
                    mb="sm"
                    {...createResponseForm.getInputProps("name")}
                />
                <Textarea
                    label={t("Response")}
                    placeholder={t("What should I say?")}
                    required
                    autosize
                    ref={textRef}
                    withAsterisk
                    {...createResponseForm.getInputProps("response")}
                />
                <Flex justify="space-between" mt="sm">
                    <Button mt="lg" type="submit" loading={createResponseLoading}>{t("Submit")}</Button>
                    <ProjectResponseAudioPlayer
                        project={project}
                        text={textValue}
                    />
                </Flex>
            </form>
        </Modal>
    )
}


export default CreateResponseModal
