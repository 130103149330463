//
//
//  Project Deployments
//
//

import phoneUrl from "../assets/phone.svg"
import alexaUrl from "../assets/alexa.svg"
import whatsappUrl from "../assets/whatsapp.svg"
import telegramUrl from "../assets/telegram.svg"
import teamsUrl from "../assets/teams.svg"
import webchatUrl from "../assets/webchat.svg"
import videochatUrl from "../assets/videochat.svg"
import {Card, Flex, rem, SimpleGrid, Title, Image, Text, Spoiler, Anchor, Button} from "@mantine/core";
import {useEffect, useState} from "react";
import {Deployment, DeploymentChannel, Project} from "../interfaces.ts";
import {useOutletContext} from "react-router-dom";
import Api from "../api.ts";
import ProjectBuildRetriever from "../components/ProjectBuildRetriever.tsx";
import { DeploymentSelect } from "../components/DeploymentSelect.tsx";
import {useSocket} from "../contexts/SocketContext.tsx";
import { useApiErrorHandler } from "../hooks.ts";


function ProjectDeployments() {
    const [project]: [Project] = useOutletContext()
    const {socket} = useSocket()
    const handleError = useApiErrorHandler()
    const [alexaLoading, setAlexaLoading] = useState(true)
    const [phoneLoading, setPhoneLoading] = useState(true)
    const [telegramLoading, setTelegramLoading] = useState(true)
    const [whatsappLoading, setWhatsappLoading] = useState(true)
    const [phoneChannels, setPhoneChannels] = useState<Deployment[]>([])
    const [telegramChannels, setTelegramChannels] = useState<Deployment[]>([])
    const [alexaChannels, setAlexaChannels] = useState<Deployment[]>([])
    const [whatsappChannels, setWhatsappChannels] = useState<Deployment[]>([])

    function getPhoneChannels() {
        setPhoneLoading(true)
        Api.getDeploymentChannelsByType("PHONE")
            .then(data => {
                setPhoneChannels(data)
                setPhoneLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setPhoneLoading(false)
            })
    }

    function getTelegramChannels() {
        setTelegramLoading(true)
        Api.getDeploymentChannelsByType("TELEGRAM")
            .then(data => {
                setTelegramChannels(data)
                setTelegramLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setTelegramLoading(false)
            })
    }

    function getAlexaChannels() {
        setAlexaLoading(true)
        Api.getDeploymentChannelsByType("ALEXA")
            .then(data => {
                setAlexaChannels(data)
                setAlexaLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setAlexaLoading(false)
            })
    }

    function getWhatsappChannels() {
        setWhatsappLoading(true)
        Api.getDeploymentChannelsByType("WHATSAPP")
            .then(data => {
                setWhatsappChannels(data)
                setWhatsappLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setWhatsappLoading(false)
            })
    }

    useEffect(() => {
        getPhoneChannels()
        getTelegramChannels()
        getAlexaChannels()
        getWhatsappChannels()
    }, [project.id])


    useEffect(() => {
        function onChannelChange(channel: DeploymentChannel) {
            if (channel.channel_type == "PHONE") {
                getPhoneChannels()
            } else if (channel.channel_type == "TELEGRAM") {
                getTelegramChannels()
            } else if (channel.channel_type == "ALEXA") {
                getAlexaChannels()
            }  else if (channel.channel_type == "WHATSAPP") {
                getWhatsappChannels()
            } 
        }

        socket.on("channel:add", onChannelChange)
        socket.on("channel:update", onChannelChange)
        socket.on("channel:delete", onChannelChange)

        socket.on("deployment:add", onChannelChange)
        socket.on("deployment:delete", onChannelChange)

        return () => {
            socket.off("channel:add", onChannelChange)
            socket.off("channel:update", onChannelChange)
            socket.off("channel:delete", onChannelChange)

            socket.off("deployment:add", onChannelChange)
            socket.off("deployment:delete", onChannelChange)
        }
    }, [socket])


    function getPhoneNotification(phoneNumber?: string ) {
        return (<>Call {phoneNumber} to interact with your model</>)
    }

    function getTelegramNotification(botName?: string) {
        return (<>You will find the bot at <Anchor href={`https://t.me/${botName}`}>{`t.me/${botName}`}</Anchor></>)
    }

    function getAlexaNotification() {
        return (<>Just wait a few minutes and say "Alexa abre MAIA" in your Echo Show 10</>)
    }

    function getWhatsappNotification(phoneNumber?: string ) {
        return (<>Text {phoneNumber} to interact with your model</>)
    }


    function getPhoneTooltip(phoneNumber?: string ) {
        return `Call ${phoneNumber} to interact with your model`
    }

    function getTelegramTooltip(botName?: string) {
        return `You will find the bot at t.me/${botName}`
    }

    function getAlexaTooltip() {
        return `Just wait a few minutes and say "Alexa abre MAIA" in your Echo Show 10`
    }

    function getWhatsappTooltip(phoneNumber?: string ) {
        return `Text ${phoneNumber} to interact with your model`
    }


    return (
        <>
            <Flex
                justify="space-between"
                align="center"
                direction="row"
                mt={rem(50)}
                mb={rem(30)}
            >
                <Title size="h1">Deployments</Title>
            </Flex>
            <Spoiler mb="xl" maxHeight={50} showLabel="Show more" hideLabel="Hide">
                In the landscape of conversational assistants development, the deployment section serves as a gateway to extend the reach of a trained model beyond the development environment. This section empowers developers to test and integrate their conversational assistants with external devices, such as smartphones or voice-enabled platforms like Alexa.
            </Spoiler>
            <ProjectBuildRetriever project={project}>
                <SimpleGrid cols={3}>
                    <Card withBorder radius="md" padding="md">
                        <Card.Section>
                            <Image
                                src={phoneUrl}
                                height={160}
                                alt="Deploy on Phone"
                            />
                        </Card.Section>
                        <Title size="h2" mt="md" mb="sm">Phone</Title>

                        <Text size="sm" c="dimmed">
                            Deploy to Phone Number.
                        </Text>

                        <DeploymentSelect
                            channels={phoneChannels}
                            currentProject={project}
                            deployApi={Api.createPhoneDeployment}
                            notification={getPhoneNotification}
                            tooltip={getPhoneTooltip}
                            metaField="phone_number"
                            loading={phoneLoading}
                        />

                    </Card>

                    <Card withBorder radius="md" padding="md">
                        <Card.Section>
                            <Image
                                src={whatsappUrl}
                                height={160}
                                alt="Deploy on WhatsApp"
                            />
                        </Card.Section>
                        <Title size="h2" mt="md" mb="sm">WhatsApp</Title>

                        <Text size="sm" c="dimmed">
                            Deploy to WhatsApp.
                        </Text>

                        <DeploymentSelect
                            channels={whatsappChannels}
                            currentProject={project}
                            deployApi={Api.createWhatsappDeployment}
                            notification={getWhatsappNotification}
                            tooltip={getWhatsappTooltip}
                            metaField="phone_number"
                            loading={whatsappLoading}
                        />

                    </Card>

                    <Card withBorder radius="md" padding="md">
                        <Card.Section>
                            <Image
                                src={telegramUrl}
                                height={160}
                                alt="Deploy on Telegram"
                            />
                        </Card.Section>
                        <Title size="h2" mt="md" mb="sm">Telegram</Title>

                        <Text size="sm" c="dimmed">
                            Deploy to Telegram.
                        </Text>

                        <DeploymentSelect 
                            channels={telegramChannels}
                            currentProject={project}
                            deployApi={Api.createTelegramDeployment}
                            notification={getTelegramNotification}
                            tooltip={getTelegramTooltip}
                            metaField="bot_name"
                            loading={telegramLoading}
                        />

                    </Card>
                    

                    <Card withBorder radius="md" padding="md">
                        <Card.Section>
                            <Image
                                src={teamsUrl}
                                height={160}
                                alt="Deploy on Teams"
                            />
                        </Card.Section>
                        <Title size="h2" mt="md" mb="sm">Teams</Title>

                        <Text size="sm" c="dimmed">
                            Deploy to Microsoft Teams.
                        </Text>

                        <Button disabled mt="md" radius="md">Coming soon</Button>

                    </Card>

                    <Card withBorder radius="md" padding="md">
                        <Card.Section>
                            <Image
                                src={webchatUrl}
                                height={160}
                                alt="Deploy on Webchat"
                            />
                        </Card.Section>
                        <Title size="h2" mt="md" mb="sm">Webchat</Title>

                        <Text size="sm" c="dimmed">
                            Deploy to Webchat.
                        </Text>

                        <Button disabled mt="md" radius="md">Coming soon</Button>

                    </Card>

                    <Card withBorder radius="md" padding="md">
                        <Card.Section>
                            <Image
                                src={videochatUrl}
                                height={160}
                                alt="Deploy on Videochat"
                            />
                        </Card.Section>
                        <Title size="h2" mt="md" mb="sm">Videochat</Title>

                        <Text size="sm" c="dimmed">
                            Deploy to Videochat.
                        </Text>

                        <Button disabled mt="md" radius="md">Coming soon</Button>

                    </Card>

                    {(project.type == "CUSTOM") &&
                    <Card withBorder radius="md" padding="md">
                        <Card.Section>
                            <Image
                                src={alexaUrl}
                                height={160}
                                alt="Deploy on Alexa"
                            />
                        </Card.Section>
                        <Title size="h2" mt="md" mb="sm">Alexa</Title>

                        <Text size="sm" c="dimmed">
                            Deploy to Echo Show 10.
                        </Text>

                        <DeploymentSelect
                            channels={alexaChannels}
                            currentProject={project}
                            deployApi={Api.createAlexaDeployment}
                            notification={getAlexaNotification}
                            tooltip={getAlexaTooltip}
                            loading={alexaLoading}
                        />
                        
                    </Card>
                    }


                </SimpleGrid>
            </ProjectBuildRetriever>
        </>
    )
}

export default ProjectDeployments
