//
//
//  Admin Projects
//
//

import { Flex, rem, Title, ActionIcon } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { DataTable } from "mantine-datatable";
import { IconExternalLink } from "@tabler/icons-react";
import Api from "../api.ts";
import { Project } from "../interfaces.ts"
import { capitalizeFirstLetter, formatDate, onResourceDelete, onResourceUpdate } from "../utils.ts";
import { Socket } from "socket.io-client";
import { useSocket } from "../contexts/SocketContext.tsx";
import { useApiErrorHandler } from "../hooks.ts";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function AdminProjects() {
    const pageLength = 25
    const { t } = useTranslation()
    const handleError = useApiErrorHandler()
    const { socket }: { socket: Socket } = useSocket()
    const [totalProjects, setTotalProjects] = useState(0)
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const projectsRef = useRef<Project[]>([])
    const [projects, setProjects] = useState<Project[]>([])

    function loadProjectsPage() {
        setLoading(true)
        Api.getProjects(page, pageLength, true)
            .then(pageProjects => {
                if (pageProjects["items"].length < 1 && page > 1) {
                    setTotalProjects(totalProjects - 1)
                } else {
                    projectsRef.current = pageProjects["items"]
                    setProjects(projectsRef.current)
                    setTotalProjects(pageProjects["total"])
                    setLoading(false)
                }
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
    }

    useEffect(() => {
        loadProjectsPage()
    }, [page])

    useEffect(() => {
        function onAdd(added: Project) {
            const newProject = { ...added, _isNew: true }
            projectsRef.current = [newProject, ...projectsRef.current]
            setTotalProjects(totalProjects+1)
            setProjects(projectsRef.current)
        }

        function onUpdate(updated: Project) {
            projectsRef.current = onResourceUpdate(updated, projects)
            setProjects(projectsRef.current)
        }

        function onDelete(deleted: Project) {
            projectsRef.current = onResourceDelete(deleted, projects)
            setTotalProjects(totalProjects-1)
            setProjects(projectsRef.current)
        }

        socket.on("project:add", onAdd)
        socket.on("project:update", onUpdate)
        socket.on("project:delete", onDelete)

        return () => {
            socket.off("project:add", onAdd)
            socket.off("project:update", onUpdate)
            socket.off("project:delete", onDelete)
        }
    }, [socket, projects])

    return (
        <>
            <Flex
                justify="space-between"
                align="center"
                direction="row"
                mt={rem(50)}
                mb={rem(30)}
            >
                <Title size="h1">{t("Projects")}</Title>
            </Flex>
            <DataTable
                horizontalSpacing="md"
                verticalSpacing="md"
                withTableBorder
                withColumnBorders
                records={projects}
                borderRadius="sm"
                highlightOnHover
                fetching={loading}
                totalRecords={totalProjects}
                recordsPerPage={pageLength}
                page={page}
                onPageChange={(p) => setPage(p)}
                columns={[
                    {
                        accessor: "",
                        render: (project) => (
                            <Link to={`/projects/${project.id}`} target="_blank" rel="noopener noreferrer">
                            <ActionIcon size="sm" variant="subtle">
                              <IconExternalLink size={16} />
                            </ActionIcon>
                          </Link>)
                    },
                    {
                        accessor: "id"
                    },
                    {
                        accessor: "name",
                        title: t("Name")
                    },
                    {
                        accessor: "type",
                        title: t("Type"),
                        render: ({type}) => {
                            return capitalizeFirstLetter(type)
                        }
                    },
                    {
                        accessor: "language",
                        title: t("Language"),
                        render: ({language}) => {
                            return capitalizeFirstLetter(language)
                        }
                    },
                    {
                        accessor: "created_by",
                        title: t("Created by"),
                        render: ({created_by}) => {
                            return created_by.name
                        }
                    },
                    {
                        accessor: "created_at",
                        title: t("Created at"),
                        render: ({created_at}) => {
                            return formatDate(new Date(created_at))
                        }
                    },
                    {
                        accessor: "updated_at",
                        title: t("Updated at"),
                        render: ({updated_at}) => {
                            return formatDate(new Date(updated_at))
                        }
                    },
                ]}
                defaultColumnProps={{
                    noWrap: true,
                    ellipsis: true,
                    cellsStyle: (user: any) => {
                        if (user._isNew) {
                            return {backgroundColor: "#F5FBEF"}
                        }
                    }
                }}
            />
        </>
    )
}
export default AdminProjects