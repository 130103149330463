//
//
//  Intent Drawer
//
//


import {Intent, Utterance} from "../interfaces.ts"
import {
    Divider,
    Table,
    Title,
    Text,
    LoadingOverlay,
    Modal,
    Button,
    Flex
} from "@mantine/core"
import classes from "./ProjectIntentDrawer.module.css"
import {useEffect, useRef, useState} from "react";
import Api from "../api.ts";
import {reverse} from "../utils.ts";
import {useAutoAnimate} from "@formkit/auto-animate/react";
import { useApiErrorHandler } from "../hooks.ts";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";


function ProjectIntentModal({intent, opened, onClose}: {intent: Intent, opened: boolean, onClose: () => void}) {
    const { t } = useTranslation()
    const [parent] = useAutoAnimate()
    const handleError = useApiErrorHandler()
    const [loading, setLoading] = useState(false)
    const utterancesRef = useRef<Utterance[]>([])
    const [utterances, setUtterances] = useState<Utterance[]>([])

    useEffect(() => {
        setLoading(true)
        if (opened) {
        Api.getIntentUtterances(intent.id)
            .then(listUtterances => {
                utterancesRef.current = listUtterances
                setUtterances(listUtterances)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
        }
    }, [opened])

    async function handleClose() {
        await setUtterances([])
        onClose()
    }

   
    return (
        <Modal
            opened={opened}
            onClose={handleClose}
            title={'Details'}
            styles={{
                content: {
                    display: "flex",
                    flexDirection: "column"
                },
                body: {
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1
                }
            }}
        >
            <Title size="h3" mb="md">{intent.name}</Title>
            <LoadingOverlay visible={loading}/>
            <Divider my="sm" />
            <div className={classes.content}>
            <Flex justify={'center'} align={'center'}>  
                {utterances.length > 0 ? (
                <Table mt="md" withTableBorder>
                    <Table.Tbody ref={parent}>
                        {reverse(utterances).map(utterance => {
                            return (
                                <Table.Tr key={utterance.id} className={classes.formRow}>
                                    <Table.Td>
                                        <Text size="sm">{utterance.utterance}</Text>
                                    </Table.Td>
                                </Table.Tr>
                            )
                        })}
                    </Table.Tbody>
                </Table>
                ):
                <Text c="dimmed" mt="sm" size="sm">{t('No sentences')}</Text>
                }
                </Flex> 
            </div>
            <div className={classes.footer}>
                    <Divider my="sm" />
                    <Button component={Link} to={`/projects/${intent.project.id}/intents`} state={{selected: intent}}>
                        {t("Go to topic")}
                    </Button>
                </div>
        </Modal>
    )
}

export default ProjectIntentModal
