//
//
//  Project Intent Suggestions Modal
//
//

import {LoadingOverlay, Modal, Table, UnstyledButton, Text, ActionIcon, Flex} from "@mantine/core";
import {useEffect, useState} from "react";
import {Intent, Project, Utterance} from "../interfaces.ts";
import Api from "../api.ts";
import {IconCirclePlus, IconRefresh} from "@tabler/icons-react";
import classes from "./ProjectIntentSuggestionsModal.module.css"
import {useOutletContext} from "react-router-dom";
import {useAutoAnimate} from "@formkit/auto-animate/react";
import {useApiErrorHandler} from "../hooks.ts";
import { useTranslation } from "react-i18next";

function ProjectIntentSuggestionsModal({opened, onClose, intent, utterances, onUtterance}: {utterances: Utterance[] | null, intent: Intent, opened: boolean, onClose: () => void, onUtterance: (utterance: string) => void}) {
    const { t } = useTranslation()
    const [parent] = useAutoAnimate()
    const handleError = useApiErrorHandler()
    const [project]: [Project] = useOutletContext()
    const [suggestions, setSuggestions] = useState<string[]>([])
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        getNewSuggestions()
    }, [intent.name, opened])

    function onAddUtterance(utterance: string) {
        setSuggestions(suggestions.filter(x => x != utterance))
        onUtterance(utterance)
    }

    function getNewSuggestions() {
        if (!opened) {
            return
        }

        setLoading(true)
        Api.createUtteranceSuggestion(intent.name, utterances!.map(x => x.utterance), project.language)
            .then(response => {
                setSuggestions(response["utterances"])
                setLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
    }

    return (
        <Modal title={t("Suggestions")} opened={opened} onClose={onClose}>
            <LoadingOverlay visible={loading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
            <Flex justify="space-between" align="center">
                <Text size="sm" c="dimmed">{t("Powered by ChatGPT")}</Text>
                <ActionIcon onClick={getNewSuggestions} variant="filled" aria-label={t("Settings")}>
                    <IconRefresh style={{ width: '70%', height: '70%' }} stroke={1.5} />
                </ActionIcon>
            </Flex>

            <Table withTableBorder mb="md" mt="sm" captionSide="top">
                <Table.Tbody ref={parent}>
                    {suggestions.map((utter, index) => {
                        return (
                             <Table.Tr key={index} className={classes.formRow}>
                                <Table.Td>
                                    <Text size="sm">{utter}</Text>
                                </Table.Td>
                                <Table.Td>
                                    <UnstyledButton onClick={() => onAddUtterance(utter)} type="submit"><IconCirclePlus size={16}/></UnstyledButton>
                                </Table.Td>
                            </Table.Tr>
                        )
                    })}
                </Table.Tbody>
            </Table>
        </Modal>
    )
}

export default ProjectIntentSuggestionsModal
