//
//
//  Project Document Build Player
//
//

import {ActionIcon, Container, rem, TextInput} from "@mantine/core";
import {useForm} from "@mantine/form";
import {IconSend} from "@tabler/icons-react";
import {useEffect, useRef, useState} from "react";
import {Button, Grid, Text, useMantineTheme} from '@mantine/core';

import classes from "../routes/ProjectPlayground/Documents.module.css"
import {Project} from "../interfaces.ts";
import Api from "../api.ts";
import ProjectBuildRetriever from "./ProjectBuildRetriever.tsx";
import { useTranslation } from "react-i18next";

// import "maia-chat-widget"  FIXME: NotSupportedError: Cannot define multiple custom elements with the same tag name

function ProjectDocumentBuildPlayer({project}: {project: Project}) {
    const { t, i18n } = useTranslation();
    const [botIsTyping, setBotIsTyping] = useState(false)
    const messagesRef = useRef<any[]>([])
    const [messages, setMessages] = useState<any[]>([])
    const [suggestions, setSuggestions] = useState<string[]| undefined>(undefined)
    const theme = useMantineTheme();

    const form = useForm({
        initialValues: {
            text: ""
        }
    })

    const languageMap: { [key: string]: string } = {
        en: 'ENGLISH',
        es: 'SPANISH',
      };

    async function getSuggestions() {
        try {
            const languageName = languageMap[i18n.language] || i18n.language;
            const suggestionResponse = await Api.suggestions(project.id, languageName);
            setSuggestions(suggestionResponse);
          } catch (error) {
            console.error(error);
          }
        
    }

    useEffect(() => {
        getSuggestions()
    }, []);

    const renderSuggestionButton = (text: string) => (
        <Grid.Col span={{ base: 10, md: 5.5 }} key={text}>
            {messages.length === 0 && (
            <Button
                variant="outline"
                size="xs"
                radius="md"
                fullWidth
                onClick={() => onSubmit({text})}
                style={{
                fontSize: theme.fontSizes.xs,
                minHeight: '50px',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                }}
            >
                <Text>{text}</Text>
            </Button>
            )}
        </Grid.Col>
    );

    async function onSubmit(values: any) {
        form.reset()

        messagesRef.current = [...messages, {
            isUser: true,
            message: {
                text: values.text,
                datetime: new Date()
            }
        }]
        setMessages(messagesRef.current)
        setBotIsTyping(true)

        const response = await Api.chat(project.id, values.text)
        const reader = response.body!.pipeThrough(new TextDecoderStream()).getReader()

        let messageIndex = -1
        // eslint-disable-next-line no-constant-condition
        while (true) {
            const {value, done} = await reader.read()
            if (done) {
                break
            }
            if (messageIndex < 0) {
                const newMessages = [...messagesRef.current]
                messageIndex = newMessages.push({
                    isUser: false,
                    message: {
                        text: value,
                        datetime: new Date()
                    }
                }) - 1
                messagesRef.current = newMessages
                setMessages(messagesRef.current)
                setBotIsTyping(false)
            } else {
                const newMessages = [...messagesRef.current]
                newMessages[messageIndex]["message"]["text"] += value

                messagesRef.current = newMessages
                setMessages(messagesRef.current)
            }
        }
    }

    return (
        <ProjectBuildRetriever project={project}>
            <Container className={classes.wrapper}>
                    <maia-message-list
                        bot-is-typing={botIsTyping ? true : undefined}
                        style={{flexGrow: 1, overflow: scroll}}
                        messages={JSON.stringify(messages)}
                    ></maia-message-list>
                    <Grid gutter="xs" justify="center" align="center" mt={2} mb={12}>
                    {suggestions &&
                        Object.values(suggestions).map((text) => renderSuggestionButton(text))}
                    </Grid>
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <TextInput
                        radius="xl"
                        size="md"
                        required
                        placeholder={t("Ask any question")}
                        rightSectionWidth={42}
                        rightSection={
                            <ActionIcon size={32} radius="xl" variant="filled" type="submit">
                                <IconSend style={{width: rem(18), height: rem(18)}} stroke={1.5}/>
                            </ActionIcon>
                        }
                        {...form.getInputProps("text")}
                    />
                </form>
            </Container>
        </ProjectBuildRetriever>
    )

}

export default ProjectDocumentBuildPlayer
