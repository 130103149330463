import { ReactNode, useEffect, useState } from 'react';
import { Combobox, Input, useCombobox, Grid, Text, Button, Tooltip, Modal, TextInput } from '@mantine/core';
import Countdown from "react-countdown";
import { Deployment, Project } from '../interfaces';
import {notifications} from "@mantine/notifications";
import Api from "../api.ts";


interface SelectOptionProps {
    item: Deployment;
    currentProject: Project;
    deployApi: (projectId: number, channelId: number) => Promise<any>;
    notification: (value?: string) => ReactNode;
    tooltip: (value?: string) => string;
    metaField?: string;
}

interface DeploymentSelectProps {
    channels: Deployment[];
    loading: boolean;
    currentProject: Project;
    deployApi: (projectId: number, channelId: number) => Promise<any>;
    notification: (value?: string) => ReactNode;
    tooltip: (value?: string) => string;
    metaField?: string;
}


function SelectOption(props: SelectOptionProps) {
    const {item, currentProject, deployApi, notification, tooltip, metaField} = props
    const [available, setAvailable] = useState(item.available)
    const [availableAt, setAvailableAt] = useState(item.available_at)
    const [inUseBy, setInUseBy] = useState<number[]>(item.in_use_by)
    const [tooltipText, setTooltipText] = useState<string>()
    const [aliasOpen, setAliasOpen] = useState(false)
    const [alias, setAlias] = useState("")
    const [aliasError, setAliasError] = useState("")

    useEffect(() => {
        if (metaField !== undefined) {
            setTooltipText(tooltip(item.meta[metaField]))
        }
        else {
            setTooltipText(tooltip())
        }
    }, [item.meta])

    useEffect(() => {
        setAvailable(item.available)
        setInUseBy(item.in_use_by)
    }, [item.available, item.in_use_by])

    useEffect(() => {
        setAvailableAt(item.available_at)
    }, [item.available_at])

    function onComplete() {
        setAvailable(true)
    }

    function onClickDeploy () {
        if (item.access_type == "PUBLIC" && currentProject.alias === null) {
            setAliasOpen(true)
        } else {
            deploy()
        }        
    }

    function onAliasChange (event: React.FormEvent<HTMLInputElement>) {
        const aux = event.currentTarget.value

        if (aux.length < 2) {
            setAliasError("Must have at least 2 characters")
            return
        } else {
            setAliasError("")
            setAlias(aux)
        }
    }

    function onAliasSubmit () {
        const values = {"alias": alias}
        Api.updateProject(currentProject.id, values)
            .then(() => {
                deploy()
                setAliasOpen(false)
                notifications.show({
                    title: 'Project updated',
                    message: 'Project updated successfully',
                    color: "green"
                })
            }).catch((err) => {
                setAliasError(err)
            })
        
    }

    function deploy() {
        deployApi(currentProject.id, item.id)
            .then(data => {
                setAvailable(data["available"])
                setInUseBy(data["in_use_by"])
                setAvailableAt(data["available_at"])

                if (metaField !== undefined) {
                    notifications.show({
                        title: 'Project deployed',
                        message: notification(data["meta"][metaField]),
                        color: "green"
                    })
                } else {
                    notifications.show({
                        title: 'Project deployed',
                        message: notification(),
                        color: "green"
                    })
                }
            })
    }

    function cancel() {
        Api.cancelDeployment(currentProject.id, item.id)
            .then(data => {
                setAvailable(true)
                setInUseBy(data["in_use_by"])
                setAvailableAt("")

                notifications.show({
                    message: "Deployment cancelled",
                    color: "red"
                })
            })
    }

    return (
        <>
        <Grid justify="space-between">
            <Tooltip label={tooltipText}>
                <Grid.Col span={7}>
                    
                        <Text fz="sm" fw={500}>
                            {item.name}
                        </Text>
                    
                    {!available ?
                        /* On property channels do not have time limit */
                        (item.project_id === null &&
                            (inUseBy?.includes(currentProject.id) ?
                                <Text fz="xs" opacity={0.6}>
                                    Deployed until <Countdown date={availableAt} onComplete={onComplete} zeroPadTime={2} zeroPadDays={0} daysInHours={true}/>
                                </Text>
                            :
                                <Text fz="xs" opacity={0.6}>
                                    Available in <Countdown date={availableAt} onComplete={onComplete} zeroPadTime={2} zeroPadDays={0} daysInHours={true}/>
                                </Text>
                            )
                        )
                        :
                        /* Public channels are always available, but project can be already deployed */
                        (inUseBy?.includes(currentProject.id) ?
                            <Text fz="xs" opacity={0.6}>
                                Already deployed
                            </Text>
                        :
                            <Text fz="xs" opacity={0.6}>
                                Available
                            </Text>
                        )
                    }
                </Grid.Col>
            </Tooltip>
            <Grid.Col span={5} style={{ display:"flex", justifyContent: "center", alignItems: "center" }}>
                {available ?
                    /* Public channel in used by current project */
                    (inUseBy?.includes(currentProject.id) ?
                        <Button color="red" onClick={cancel}>Cancel</Button>
                        :
                        <Button onClick={onClickDeploy}>Deploy</Button>
                    )
                    :
                    (inUseBy?.includes(currentProject.id) ?
                        <Button color="red" onClick={cancel}>Cancel</Button>
                        :
                        <Button disabled>Busy</Button>
                    )
                }
            </Grid.Col>
        </Grid>
        <Modal zIndex={400} opened={aliasOpen} onClose={() => setAliasOpen(false)} title="Specify an alias for your project">
            <TextInput
                data-autofocus
                mt="xs"
                withAsterisk
                label="Project alias"
                required
                onChange={onAliasChange}
                error={aliasError}
            />
            <Button type="submit" mt="xs" onClick={onAliasSubmit}>Submit</Button>
        </Modal>
        </>
    );
}

export function DeploymentSelect(props: DeploymentSelectProps) {
    const {channels, loading, currentProject, deployApi, notification, tooltip, metaField} = props
    const combobox = useCombobox();
    const [privateOptions, setPrivateOptions] = useState<JSX.Element[]>([])
    const [publicOptions, setPublicOptions] = useState<JSX.Element[]>([])
    const [onPropertyOptions, setOnPropertyOptions] = useState<JSX.Element[]>([])

    useEffect(() => {
        const privateOptionsAux = channels.filter((item => item.access_type == "PRIVATE"))
            .map((item) => (
                <Combobox.Option value={item.name} key={item.name}>
                    <SelectOption
                        item={item}
                        currentProject={currentProject}
                        deployApi={deployApi}
                        notification={notification}
                        tooltip={tooltip}
                        metaField={metaField}
                    />
                </Combobox.Option>
            ));
        setPrivateOptions(privateOptionsAux)

        const publicOptionsAux = channels.filter((item => item.access_type == "PUBLIC"))
            .map((item) => (
                <Combobox.Option value={item.name} key={item.name}>
                    <SelectOption
                        item={item}
                        currentProject={currentProject}
                        deployApi={deployApi}
                        notification={notification}
                        tooltip={tooltip}
                        metaField={metaField}
                    />
                </Combobox.Option>
            ));
        setPublicOptions(publicOptionsAux)

        const onPropertyOptionsAux = channels.filter((item => (
                item.access_type == "ON_PROPERTY" &&
                item.project_id === currentProject.id
            )))
            .map((item) => (
                <Combobox.Option value={item.name} key={item.name}>
                    <SelectOption
                        item={item}
                        currentProject={currentProject}
                        deployApi={deployApi}
                        notification={notification}
                        tooltip={tooltip}
                        metaField={metaField}
                    />
                </Combobox.Option>
            ));
            setOnPropertyOptions(onPropertyOptionsAux)
    }, [channels])

    return (
        <Combobox
            store={combobox}
            withinPortal={true}
        >
            <Combobox.Target>
                <Button 
                    onClick={() => combobox.toggleDropdown()}
                    variant="light" 
                    color="blue" 
                    fullWidth 
                    mt="md" 
                    radius="md"
                    disabled={channels.length == 0}
                    loading={loading}
                    rightSection={<Combobox.Chevron />}
                >
                    <Input.Placeholder style={{ color: "#459bea", fontWeight: "bold"}}>Deploy</Input.Placeholder>
                </Button>
            </Combobox.Target>

            <Combobox.Dropdown>
                <Combobox.Options mah={500} style={{ overflowY: 'auto' }}>
                    {onPropertyOptions.length > 0 &&
                        <Combobox.Group label="On property channels">
                            {onPropertyOptions}
                        </Combobox.Group>
                    }
                    {privateOptions.length > 0 &&
                        <Combobox.Group label="Private channels">
                            {privateOptions}
                        </Combobox.Group>
                    }
                    {publicOptions.length > 0 &&
                        <Combobox.Group label="Public channels">
                            {publicOptions}
                        </Combobox.Group>
                    }
                </Combobox.Options>
            </Combobox.Dropdown>
        </Combobox>
  );
}