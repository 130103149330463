//
//
//  Response Drawer
//
//

import {Button, Divider, Flex, Modal, Text, Title} from "@mantine/core"
import {Project, Response} from "../interfaces.ts"
import classes from "./ProjectResponseDrawer.module.css"
import {Link, useOutletContext} from "react-router-dom";
import ProjectResponseAudioPlayer from "./ProjectResponseAudioPlayer.tsx";
import { useTranslation } from "react-i18next";


function ProjectResponseModal({response, opened, onClose}: {
    response: Response,
    opened: boolean,
    onClose: () => void,
}) {
    const { t } = useTranslation();
    const [project]: [Project] = useOutletContext()

    return (
        <Modal
            opened={opened}
            onClose={onClose}
            title={t('Details')}
            styles={{
                content: {
                    display: "flex",
                    flexDirection: "column"
                },
                body: {
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1
                }
            }}
        >
            <div className={classes.content}>
                <Flex direction="column">
                        <Title
                            mb="md"
                            size="h3"
                        >
                        {response.name}
                        </Title>
                        <Divider my="sm" />
                        <Flex justify="space-between" align={'center'}>
                        <Text>
                            {response.response}
                        </Text>
                        <ProjectResponseAudioPlayer
                                project={project}
                                text={response.response}
                            />
                        </Flex>
                </Flex>
            </div>
            <div className={classes.footer}>
                    <Divider mb="sm" />
                    <Button component={Link} to={`/projects/${response.project.id}/responses`} state={{selected: response}}>
                        {t("Go to response")}
                    </Button>
                </div>
        </Modal>
    )
}

export default ProjectResponseModal
