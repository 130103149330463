//
//
//  Project Create
//
//

import {
    Anchor, Box,
    Button, Center,
    Container,
    Flex,
    Group, LoadingOverlay,
    rem,
    Select,
    SimpleGrid,
    Stepper,
    TextInput,
    Title,
} from "@mantine/core";
import {ReactNode, useState} from "react";
import {IconArrowLeft, IconCircleCheck, IconBrandTelegram, IconPhone, IconBrandAmazon, IconBrandWhatsapp} from "@tabler/icons-react";
import IconRadio from "../components/IconRadio.tsx";
import {Link, useNavigate} from "react-router-dom";
import {useForm} from "@mantine/form";
import Api from "../api.ts";


function DeploymentChannelCreate() {
    const navigate = useNavigate()
    const [channelType, setChannelType] = useState<string>("PHONE")
    const [accessType, setAccessType] = useState<string>("PRIVATE")
    const [active, setActive] = useState(0)
    const [createLoading, setCreateLoading] = useState(false)
    const [highestStepVisited, setHighestStepVisited] = useState(active)

    const phoneChannelMetadataForm = useForm({
        initialValues: {
            name: "",
            access_type: accessType,
            redis_key: "",
            project_id: null,
            conversation_manager_channel_id: -1,
            phone_number: ""
        }
    })

    const telegramChannelMetadataForm = useForm({
        initialValues: {
            name: "",
            access_type: accessType,
            redis_key: "",
            project_id: null,
            conversation_manager_channel_id: -1,
            bot_name: "",
            bot_token: ""
        }
    })

    const alexaChannelMetadataForm = useForm({
        initialValues: {
            name: "",
            access_type: accessType,
            redis_key: "",
            project_id: null,
            conversation_manager_channel_id: -1
        }
    })

    const whatsappChannelMetadataForm = useForm({
        initialValues: {
            name: "",
            access_type: accessType,
            redis_key: "",
            project_id: null,
            conversation_manager_channel_id: -1,
            phone_number: "",
            phone_number_id: ""
        }
    })

    function onChangeType(changedType: string) {
        setChannelType(changedType)
    }

    function goToNextStep(nextStep: number) {
        setActive((current) => (current < 3 ? current + 1 : current))
        setHighestStepVisited((hSC) => Math.max(hSC, nextStep))
    }

    function goToPrevStep() {
        setActive((current) => (current > 0 ? current - 1 : current))
        setAccessType("PRIVATE")
    }

    const shouldAllowSelectStep = (step: number) => highestStepVisited >= step && active !== step

    function onAccessTypeChange(value: string | null) {
        if (value) {
            if (channelType == "PHONE") {
                phoneChannelMetadataForm.setFieldValue("access_type", value)
            } else if (channelType == "TELEGRAM") {
                telegramChannelMetadataForm.setFieldValue("access_type", value)
            } else if (channelType == "ALEXA") {
                alexaChannelMetadataForm.setFieldValue("access_type", value)
            } else if (channelType == "WHATSAPP") {
                whatsappChannelMetadataForm.setFieldValue("access_type", value)
            } else {
                return
            }
            
            setAccessType(value)
        }
    }

    function onSubmitMetadataPhone(values: any) {
        setCreateLoading(true)
        Api.createPhoneDeploymentChannel(
            values.name,
            values.access_type,
            values.project_id,
            values.phone_number
        )
            .then(() => {
                setCreateLoading(false)
                navigate(`/admin/channels`)
            })
    }

    function onSubmitMetadataTelegram(values: any) {
        setCreateLoading(true)
        Api.createTelegramDeploymentChannel(
            values.name,
            values.access_type,
            values.project_id,
            values.bot_name,
            values.bot_token
        )
            .then(() => {
                setCreateLoading(false)
                navigate(`/admin/channels`)
            })
    }

    function onSubmitMetadataAlexa(values: any) {
        setCreateLoading(true)
        Api.createAlexaDeploymentChannel(
            values.name,
            values.access_type,
            values.project_id
        )
            .then(() => {
                setCreateLoading(false)
                navigate(`/admin/channels`)
            })
    }

    function onSubmitMetadataWhatsapp(values: any) {
        setCreateLoading(true)
        Api.createWhatsappDeploymentChannel(
            values.name,
            values.access_type,
            values.project_id,
            values.phone_number,
            values.phone_number_id
        )
            .then(() => {
                setCreateLoading(false)
                navigate(`/admin/channels`)
            })
    }
    

    let typeForm: ReactNode
    if (channelType === "PHONE") {
        typeForm = (
            <form onSubmit={phoneChannelMetadataForm.onSubmit(onSubmitMetadataPhone)}>
                <TextInput
                    withAsterisk
                    label="Name"
                    required
                    placeholder="Super duper channel"
                    {...phoneChannelMetadataForm.getInputProps("name")}
                />
                <Select
                    mt="xs"
                    withAsterisk
                    description="You will NOT be able to change it later."
                    label="Access permission"
                    placeholder="Select access permission"
                    data={[
                        {"label": "Public", "value": "PUBLIC"},
                        {"label": "Private", "value": "PRIVATE"},
                        {"label": "On property", "value": "ON_PROPERTY"}
                    ]}
                    allowDeselect={false}
                    {...phoneChannelMetadataForm.getInputProps("access_type")}
                    onChange={onAccessTypeChange}
                    value={accessType}
                    
                />
                {accessType == "ON_PROPERTY" &&
                <TextInput
                    mt="xs"
                    withAsterisk
                    label="Project"
                    placeholder="Specify a project ID"
                    required
                    {...phoneChannelMetadataForm.getInputProps("project_id")}
                />
                }
                
                <TextInput
                    withAsterisk
                    label="Phone number"
                    required
                    placeholder="Super duper phone number"
                    {...phoneChannelMetadataForm.getInputProps("phone_number")}
                />

                <Group justify="center" mt={48}>
                    <Button type="submit" variant="default" onClick={goToPrevStep}>
                        Back
                    </Button>
                    <Button type="submit">Create</Button>
                </Group>
            </form>
        )
    } else if (channelType === "TELEGRAM") {
        typeForm = (
            <form onSubmit={telegramChannelMetadataForm.onSubmit(onSubmitMetadataTelegram)}>
                <TextInput
                    withAsterisk
                    label="Name"
                    required
                    placeholder="Super duper channel"
                    {...telegramChannelMetadataForm.getInputProps("name")}
                />
                <Select
                    mt="xs"
                    withAsterisk
                    description="You will NOT be able to change it later."
                    label="Access permission"
                    placeholder="Select access permission"
                    data={[
                        {"label": "Public", "value": "PUBLIC"},
                        {"label": "Private", "value": "PRIVATE"},
                        {"label": "On property", "value": "ON_PROPERTY"}
                    ]}
                    allowDeselect={false}
                    {...telegramChannelMetadataForm.getInputProps("access_type")}
                    onChange={onAccessTypeChange}
                    value={accessType}
                />
                {accessType == "ON_PROPERTY" &&
                <TextInput
                    mt="xs"
                    withAsterisk
                    label="Project"
                    placeholder="Specify a project ID"
                    required
                    {...telegramChannelMetadataForm.getInputProps("project_id")}
                />
                }

                <TextInput
                    withAsterisk
                    label="Bot name"
                    required
                    placeholder="Super duper bot name"
                    {...telegramChannelMetadataForm.getInputProps("bot_name")}
                />

                <TextInput
                    withAsterisk
                    label="Bot token"
                    required
                    placeholder="Super duper bot token"
                    {...telegramChannelMetadataForm.getInputProps("bot_token")}
                />
                

                <Group justify="center" mt={48}>
                    <Button type="submit" variant="default" onClick={goToPrevStep}>
                        Back
                    </Button>
                    <Button type="submit">Create</Button>
                </Group>
            </form>
        )
    } else if (channelType === "WHATSAPP") {
        typeForm = (
            <form onSubmit={whatsappChannelMetadataForm.onSubmit(onSubmitMetadataWhatsapp)}>
                <TextInput
                    withAsterisk
                    label="Name"
                    required
                    placeholder="Super duper channel"
                    {...whatsappChannelMetadataForm.getInputProps("name")}
                />
                <Select
                    mt="xs"
                    withAsterisk
                    description="You will NOT be able to change it later."
                    label="Access permission"
                    placeholder="Select access permission"
                    data={[
                        {"label": "Public", "value": "PUBLIC"},
                        {"label": "Private", "value": "PRIVATE"},
                        {"label": "On property", "value": "ON_PROPERTY"}
                    ]}
                    allowDeselect={false}
                    {...whatsappChannelMetadataForm.getInputProps("access_type")}
                    onChange={onAccessTypeChange}
                    value={accessType}
                    
                />
                {accessType == "ON_PROPERTY" &&
                <TextInput
                    mt="xs"
                    withAsterisk
                    label="Project"
                    placeholder="Specify a project ID"
                    required
                    {...whatsappChannelMetadataForm.getInputProps("project_id")}
                />
                }
                
                <TextInput
                    withAsterisk
                    label="Phone number"
                    required
                    placeholder="Super duper phone number"
                    {...whatsappChannelMetadataForm.getInputProps("phone_number")}
                />

                <TextInput
                    withAsterisk
                    label="Phone number ID"
                    required
                    placeholder="Super duper phone number ID"
                    {...whatsappChannelMetadataForm.getInputProps("phone_number_id")}
                />

                <Group justify="center" mt={48}>
                    <Button type="submit" variant="default" onClick={goToPrevStep}>
                        Back
                    </Button>
                    <Button type="submit">Create</Button>
                </Group>
            </form>
        )
    } else if (channelType === "ALEXA") {
        typeForm = (
            <form onSubmit={alexaChannelMetadataForm.onSubmit(onSubmitMetadataAlexa)}>
                <TextInput
                    withAsterisk
                    label="Name"
                    required
                    placeholder="Super duper channel"
                    {...alexaChannelMetadataForm.getInputProps("name")}
                />
                <Select
                    mt="xs"
                    withAsterisk
                    description="You will NOT be able to change it later."
                    label="Access permission"
                    placeholder="Select access permission"
                    data={[
                        {"label": "Public", "value": "PUBLIC"},
                        {"label": "Private", "value": "PRIVATE"},
                        {"label": "On property", "value": "ON_PROPERTY"}
                    ]}
                    allowDeselect={false}
                    {...alexaChannelMetadataForm.getInputProps("access_type")}
                    onChange={onAccessTypeChange}
                    value={accessType}
                />
                {accessType == "ON_PROPERTY" &&
                <TextInput
                    mt="xs"
                    withAsterisk
                    label="Project"
                    placeholder="Specify a project ID"
                    required
                    {...alexaChannelMetadataForm.getInputProps("project_id")}
                />
                }
                <Group justify="center" mt={48}>
                    <Button type="submit" variant="default" onClick={goToPrevStep}>
                        Back
                    </Button>
                    <Button type="submit">Create</Button>
                </Group>
            </form>
        )
    } else {
        throw new Error(`Type not implemented: ${channelType}`)
    }

    const humanReadableType = {
        "PHONE": "Phone",
        "TELEGRAM": "Telegram",
        "ALEXA": "Alexa",
        "WHATSAPP": "WhatsApp"
    }

    return (
        <>
            <main>
                <Container>
                    <Flex
                        direction="column"
                        mt={rem(50)}
                        mb={rem(50)}
                    >
                        <Title size="h1">Create deployment</Title>
                        <Anchor c="dimmed" size="sm" mt="md" component={Link} to="/admin/channels">
                            <Center inline>
                                <IconArrowLeft style={{width: rem(12), height: rem(12)}} stroke={1.5}/>
                                <Box ml={5}>Back to Deployments</Box>
                            </Center>
                        </Anchor>
                    </Flex>
                    <LoadingOverlay visible={createLoading}/>
                    <Stepper
                        active={active}
                        onStepClick={setActive}
                        completedIcon={<IconCircleCheck style={{width: rem(18), height: rem(18)}}/>}
                    >
                        <Stepper.Step
                            label="Type"
                            description={(channelType == null || active === 0) ? "Define the type of the deployment" : humanReadableType[channelType]}
                            allowStepSelect={shouldAllowSelectStep(0)}
                        >
                            <SimpleGrid cols={3} mt={64} spacing="xl">
                                <IconRadio
                                    enabled={true}
                                    checked={channelType === "PHONE"}
                                    defaultChecked={true}
                                    onChange={() => onChangeType("PHONE")}
                                    title={humanReadableType["PHONE"]}
                                    description="Create a phone deployment"
                                    icon={<IconPhone/>}
                                />
                                <IconRadio
                                    enabled={true}
                                    checked={channelType == "TELEGRAM"}
                                    defaultChecked={false}
                                    onChange={() => onChangeType("TELEGRAM")}
                                    title={humanReadableType["TELEGRAM"]}
                                    description="Create a Telegram deployment"
                                    icon={<IconBrandTelegram/>}
                                />
                                <IconRadio
                                    enabled={true}
                                    checked={channelType == "ALEXA"}
                                    defaultChecked={false}
                                    onChange={() => onChangeType("ALEXA")}
                                    title={humanReadableType["ALEXA"]}
                                    description="Create an Alexa deployment"
                                    icon={<IconBrandAmazon/>}
                                />
                                <IconRadio
                                    enabled={true}
                                    checked={channelType == "WHATSAPP"}
                                    defaultChecked={false}
                                    onChange={() => onChangeType("WHATSAPP")}
                                    title={humanReadableType["WHATSAPP"]}
                                    description="Create WhatsApp deployment"
                                    icon={<IconBrandWhatsapp/>}
                                />
                            </SimpleGrid>
                            <Group justify="center" mt={48}>
                                <Button onClick={() => goToNextStep(active + 1)}>Next</Button>
                            </Group>
                        </Stepper.Step>
                        <Stepper.Step
                            label="Metadata"
                            description="Define metadata"
                            allowStepSelect={shouldAllowSelectStep(1)}
                        >
                            {typeForm}
                        </Stepper.Step>
                    </Stepper>
                </Container>
            </main>
        </>
    )
}

export default DeploymentChannelCreate
