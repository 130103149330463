//
//
//  Channels Data Table
//
//

import {DeploymentChannel} from "../interfaces.ts";
import {Anchor, Table} from "@mantine/core";
import {DataTableEmpty, DataTableLoading} from "./DataTable.tsx";
import {useAutoAnimate} from "@formkit/auto-animate/react";


function ChannelsDataTable(
    {channels, newChannels, loading=false, onCreate, onSelection}: 
    {
        channels: DeploymentChannel[], 
        newChannels: DeploymentChannel[],
        loading: boolean,
        onCreate: () => void,
        onSelection: (channel: DeploymentChannel) => void
    }
) {
    const [parent] = useAutoAnimate()

    if (loading) {
        return <DataTableLoading/>
    }

    if (!channels.length) {
        return <DataTableEmpty
            title="You don't have any deployment"
            subtitle=""
            action="Create your first Deployment"
            onAction={onCreate}
        />
    }

    const humanReadableChannelType: Record<string, string> = {
        "PHONE": "Phone",
        "TELEGRAM": "Telegram",
        "ALEXA": "Alexa",
        "WHATSAPP": "WhatsApp"
    }

    const humanReadableAccessType: Record<string, string> = {
        "PUBLIC": "Public",
        "PRIVATE": "Private",
        "ON_PROPERTY": "On property"
    }


    return (
        <Table>
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>ID</Table.Th>
                    <Table.Th>Name</Table.Th>
                    <Table.Th>Channel type</Table.Th>
                    <Table.Th>Access type</Table.Th>
                </Table.Tr>
                </Table.Thead>
            <Table.Tbody ref={parent}>
                {newChannels.map((channel: DeploymentChannel) => {
                    return (
                        <Table.Tr key={channel.id} style={{backgroundColor: "#F5FBEF"}}>
                            <Table.Td>
                                {channel.id}
                            </Table.Td>
                            <Table.Td>
                                <Anchor onClick={() => onSelection(channel)}>
                                    {channel.name}
                                </Anchor>
                            </Table.Td>
                            <Table.Td>
                                {humanReadableChannelType[channel.channel_type]}
                            </Table.Td>
                            <Table.Td>
                                {humanReadableAccessType[channel.access_type]}
                            </Table.Td>
                        </Table.Tr>
                    )
                })}

                {channels.map((channel: DeploymentChannel) => {
                    return (
                        <Table.Tr key={channel.id}>
                            <Table.Td>
                                {channel.id}
                            </Table.Td>
                            <Table.Td>
                                <Anchor onClick={() => onSelection(channel)}>
                                    {channel.name}
                                </Anchor>
                            </Table.Td>
                            <Table.Td>
                                {humanReadableChannelType[channel.channel_type]}
                            </Table.Td>
                            <Table.Td>
                                {humanReadableAccessType[channel.access_type]}
                            </Table.Td>
                        </Table.Tr>
                    )
                })}
            </Table.Tbody>
        </Table>
    )
}

export default ChannelsDataTable