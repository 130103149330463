//
//
//  Create Intent Modal
//
//

import { Button, Group, LoadingOverlay, Modal, TextInput, Title } from "@mantine/core"
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useApiErrorHandler } from "../hooks";
import { useLocation, useOutletContext } from "react-router-dom";
import { useForm } from "@mantine/form";
import { Intent, Project, Utterance } from "../interfaces";
import Api from "../api";
import UtterancesTable from "./UtterancesTable";
import { notifications } from "@mantine/notifications";
import { onResourceAdd, onResourceDelete, onResourceUpdate } from "../utils";
import { useSocket } from "../contexts/SocketContext";
import { Socket } from "socket.io-client";

interface createIntentForm {
    name: string
}

function CreateIntentModal({ createIntentOpen, onCloseCreateIntent}: { createIntentOpen: boolean, onCloseCreateIntent: (intent?: Intent) => void}) {

    const { t } = useTranslation()
    const location = useLocation()
    const {socket}: {socket: Socket} = useSocket()
    const [createIntentLoading, setCreateIntentLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [utterances, setUtterances] = useState<Utterance[]>([])
    const handleError = useApiErrorHandler()
    const [project]: [Project] = useOutletContext()
    const utterancesRef = useRef<Utterance[]>([])
    const [loading, setLoading] = useState(false)

    const [selectedIntent, setSelectedIntent] = useState<Intent | null>(location.state?.selected)

    const createIntentForm = useForm({
        initialValues: {
            name: ""
        }
    })

    useEffect(() => {
        
        if (selectedIntent) {
            setLoading(true)
        Api.getIntentUtterances(selectedIntent.id)
            .then(listUtterances => {
                utterancesRef.current = listUtterances
                setUtterances(listUtterances)
                setLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setLoading(false)
            })
        }
    }, [selectedIntent?.id])

    useEffect(() => {
        function onAdd(added: Utterance) {
            if (added.intent.id === selectedIntent?.id) {
                utterancesRef.current = onResourceAdd(added, utterancesRef.current)
                setUtterances(utterancesRef.current)
            }
        }

        function onUpdate(updated: Utterance) {
            if (updated.intent.id === selectedIntent?.id) {
                utterancesRef.current = onResourceUpdate(updated, utterancesRef.current)
                setUtterances(utterancesRef.current)
            }
        }

        function onDelete(deleted: Utterance) {
            if (deleted.intent.id === selectedIntent?.id) {
                utterancesRef.current = onResourceDelete(deleted, utterancesRef.current)
                setUtterances(utterancesRef.current)
            }
        }

        socket.on("intent_utterance:add", onAdd)
        socket.on("intent_utterance:update", onUpdate)
        socket.on("intent_utterance:delete", onDelete)

        return () => {
            socket.off("intent_utterance:add", onAdd)
            socket.off("intent_utterance:update", onUpdate)
            socket.off("intent_utterance:delete", onDelete)
        }
    }, [selectedIntent?.id, socket]);

    function onUtteranceAdd(added: Utterance) {
        if (added.intent.id === selectedIntent?.id) {
            utterancesRef.current = onResourceAdd(added, utterancesRef.current)
            setUtterances(utterancesRef.current)
        }
    }

    function handleCloseCreateIntent() {
        setSelectedIntent(null)
        createIntentForm.reset()
        setUtterances([])
        if (selectedIntent) {
            onCloseCreateIntent(selectedIntent)
        } else {
            onCloseCreateIntent()
        }

        
    }

    function onSubmitCreateIntent(values: createIntentForm) {
        setCreateIntentLoading(true)
        if (!selectedIntent) {
            Api.createIntent(project.id, values.name)
            .then(intent => {
                setCreateIntentLoading(false)
                setSelectedIntent(intent)

            }).catch((err) => {
                console.error(err);
                handleError(err)
                setCreateIntentLoading(false)
            })
        } else {
        
        Api.updateIntent(selectedIntent.id, values)
            .then(() => {
                setCreateIntentLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setCreateIntentLoading(false)
            })
        }
    }
    function onDeleteIntent(intent: Intent) {
        setDeleteLoading(true)
        Api.deleteIntent(intent.id)
            .then(() => {
                setDeleteLoading(false)
                handleCloseCreateIntent()
            }).catch(err => {
                if (err.response.status == 409) {
                    // Intent used in story
                    err.response.json()
                        .then((data: any) => {
                            if (data["detail"]["code"] === "used_by_story") {
                                notifications.show({
                                    title: t("Error"),
                                    message: t("Topic is in use by story") + ` ${data["detail"]["story"]["name"]}. ` + t("Please delete story before."),
                                    color: "red"
                                })
                                setDeleteLoading(false)
                            }
                        })
                } else {
                    console.error(err);
                    handleError(err)
                    setDeleteLoading(false)
                }
            })
    }

    return (
        <Modal opened={createIntentOpen} onClose={handleCloseCreateIntent} title={t("New Topic")}>
            <form onSubmit={createIntentForm.onSubmit(onSubmitCreateIntent)}>
                {selectedIntent ? (
                    <Title size={'h3'}>{selectedIntent.name}</Title>
                ):(
                <>
                <TextInput
                    withAsterisk
                    label={t("Name")}
                    required
                    placeholder={t("Super duper topic")}
                    {...createIntentForm.getInputProps("name")}
                />
                <Group align="right" mt="md">
                    <Button type="submit" loading={createIntentLoading}>{t("Submit")}</Button>
                </Group>
                </>
                )}
                
            </form>
            <LoadingOverlay visible={loading} />
            {selectedIntent && (
                <UtterancesTable
                    intent={selectedIntent}
                    utterances={utterances}
                    onUtteranceAdd={onUtteranceAdd}
                    onDelete={onDeleteIntent}
                    deleteLoading={deleteLoading}
                />
            )}
        </Modal>
    )
}

export default CreateIntentModal
