//
//
//  Project Settings
//
//

import { useNavigate, useOutletContext } from "react-router-dom"
import {
    Button,
    rem,
    TextInput,
    Title,
    Divider,
    Text,
    Stack,
    Checkbox,
    ColorInput,
    Textarea,
    Spoiler,
    Flex,
    SimpleGrid,
    Select,
    ComboboxItem
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { modals } from '@mantine/modals'
import { IconRefresh, IconTrash, IconEye } from "@tabler/icons-react";
import Api from "../../api.ts";
import { useEffect, useState } from "react";
import { CustomProject, Team } from "../../interfaces.ts";
import classes from "./Custom.module.css"
import { useUser } from "../../contexts/AuthContext.tsx";
import { notifications } from "@mantine/notifications";
import CopyButton from "../../components/CopyButton.tsx";
import MediaUpload from "../../components/MediaUpload.tsx";
import { megabytes } from "../../utils.ts";
import { useApiErrorHandler } from "../../hooks.ts";
import IconCheckbox from "../../components/IconCheckbox.tsx";
import { useTranslation } from "react-i18next";


function ProjectSettingsCustom() {
    const { t } = useTranslation()
    const { user } = useUser()
    const navigate = useNavigate()
    const handleError = useApiErrorHandler()
    const [uploadLogoLoading, setUploadLogoLoading] = useState(false)
    const [project, setProject]: [CustomProject, (project: CustomProject) => void] = useOutletContext()
    const [rebuildLoading, setRebuildLoading] = useState(false)
    const [updateLoading, setUpdateLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [userTeams, setUserTeams] = useState<ComboboxItem[]>([])

    const form = useForm({
        initialValues: {
            type: project.type,
            name: project.name,
            alias: project.alias,
            description: project.description || undefined,
            capabilities_chat: project.capabilities_chat,
            capabilities_videochat: project.capabilities_videochat,
            is_public: project.is_public,
            videochat_background_color: project.videochat_background_color || undefined,
            videochat_toolbar_color: project.videochat_toolbar_color || undefined,
            chat_widget_hover_background_color: project.chat_widget_hover_background_color || undefined,
            alexa_skill_invocation_name: project.alexa_skill_invocation_name || undefined,
            team_id: project.team?.id.toString() || undefined
        },
        validate: {
            name: (value: string) => value.length < 2 ? t("At least 2 characters") : null
        }
    })

    useEffect(() => {
        form.setValues({
            name: project.name,
            alias: project.alias,
            description: project.description || undefined,
            capabilities_chat: project.capabilities_chat,
            capabilities_videochat: project.capabilities_videochat,
            is_public: project.is_public,
            videochat_background_color: project.videochat_background_color || undefined,
            videochat_toolbar_color: project.videochat_toolbar_color || undefined,
            alexa_skill_invocation_name: project.alexa_skill_invocation_name || undefined,
            team_id: project.team?.id.toString() || undefined
        })
    }, [project])

    useEffect(() => {
        setUpdateLoading(true)
        Api.getTeams(1, 100)
            .then(teams => {
                const aux = teams.items.map((team: Team) => {
                    return {"label": team.name, "value": team.id.toString()} as ComboboxItem
                })
                if (aux !== undefined) {
                    setUserTeams(aux)
                }
                setUpdateLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setUpdateLoading(false)
            })
    }, [user])

    function onSubmit(values: any) {
        if (!values["alexa_skill_invocation_name"]) {
            values["alexa_skill_invocation_name"] = null
        }

        setUpdateLoading(true)
        Api.updateProject(project.id, values)
            .then(updatedProject => {
                setProject(updatedProject)
                setUpdateLoading(false)
                notifications.show({
                    title: t('Project updated'),
                    message: t('Project updated successfully'),
                    color: "green"
                })
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setUpdateLoading(false)
            })
    }

    const openDeleteModal = () =>
        modals.openConfirmModal({
            title: t('Delete project'),
            centered: true,
            children: (
                <Text size="sm">
                    {t("Are you sure you want to delete your project?")}
                </Text>
            ),
            labels: { confirm: t('Delete project'), cancel: t("No, don't delete it") },
            confirmProps: { color: 'red' },
            onConfirm: () => {
                setDeleteLoading(true)
                Api.deleteProject(project.id)
                    .then(() => {
                        navigate("/projects")
                    }).catch((err) => {
                        console.error(err);
                        handleError(err)
                        setUpdateLoading(false)
                    })
            }
        })

    function rebuild() {
        setRebuildLoading(true)
        Api.rebuildProject(project.id)
            .then(() => {
                setRebuildLoading(false)
                notifications.show({
                    title: t('Project rebuilt'),
                    message: t('Please wait a few seconds'),
                    color: "green"
                })
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setUpdateLoading(false)
            })
    }

    function onDropLogo(file: File) {
        setUploadLogoLoading(true)
        Api.createProjectLogo(project.id, file)
            .then(() => {
                setUploadLogoLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setUpdateLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setUpdateLoading(false)
            })
    }

    function onDeleteLogo() {
        setUploadLogoLoading(true)
        Api.deleteProjectLogo(project.id)
            .then(() => {
                setUploadLogoLoading(false)
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setUpdateLoading(false)

            })
    }

    return (
        <>
            <Title size="h1" mb={rem(20)} mt={rem(50)}>{t("Settings")}</Title>
            <Spoiler mb="xl" maxHeight={50} showLabel={t("Show more")} hideLabel={t("Hide")}>
                {t("The Settings page is a dedicated space within each conversational assistant project that empowers users to customize and configure various aspects to suit their specific needs. It serves as a control center for managing project-specific parameters, fine-tuning behaviors, and ensuring the conversational assistant aligns with desired specifications.")}
            </Spoiler>
            <Flex direction="column" style={{ width: "25%" }} mb="sm">
                <Title size="h6" mb={6}>{t("Logo")}</Title>
                <MediaUpload
                    loading={uploadLogoLoading}
                    onDrop={onDropLogo}
                    onDelete={onDeleteLogo}
                    maxSize={megabytes(10)}
                    acceptImages={true}
                    acceptVideos={false}
                    image={project?.logo}
                />
            </Flex>
            <form onSubmit={form.onSubmit(onSubmit)} className={classes.form}>
                <TextInput
                    placeholder={t("Name")}
                    label={t("Name")}
                    withAsterisk
                    required {...form.getInputProps("name")}
                />
                <TextInput
                    placeholder="Alias"
                    label="Alias"
                    {...form.getInputProps("alias")}
                />
                <Textarea
                    label={t("Description")}
                    autosize
                    mt="sm"
                    minRows={2}
                    placeholder={t("Description of super duper project")}
                    {...form.getInputProps("description")}
                />

                <SimpleGrid cols={1} mt={32} spacing="xl">
                    <IconCheckbox
                        enabled={true}
                        defaultChecked={false}
                        title={t("Set as a public project")}
                        description={t("Everyone can interact with it, but only you can modify it")}
                        icon={<IconEye/>}
                        {...form.getInputProps("is_public", { type: 'checkbox' })}
                    />
                </SimpleGrid>

                <Title mb="xs" size="h4" mt="lg">{t("Build Capabilities")}</Title>
                <Stack>
                    <Checkbox
                        label="Chat"
                        {...form.getInputProps('capabilities_chat', { type: "checkbox" })}
                    />
                    <Checkbox
                        label="Video-Chat"
                        {...form.getInputProps('capabilities_videochat', { type: "checkbox" })}
                    />
                </Stack>

                <Title mb="xs" size="h4" mt="lg">Video-Chat</Title>
                <ColorInput
                    format="rgba"
                    label={t("Background Color")}
                    {...form.getInputProps('videochat_background_color')}
                />
                <ColorInput
                    format="rgba"
                    label={t("Toolbar Color")}
                    {...form.getInputProps('videochat_toolbar_color')}
                />

                <Title mb="xs" size="h4" mt="lg">Chat</Title>
                <ColorInput
                    format="rgba"
                    label={t("Background Color on hover")}
                    {...form.getInputProps('chat_widget_hover_background_color')}
                />

                <Title mb="xs" size="h4" mt="lg">{t("Deployments")}</Title>
                <Title mb="xs" size="h5" mt="lg">Alexa</Title>
                <TextInput
                    label={t("Skill Invocation Name")}
                    mt="sm"
                    {...form.getInputProps("alexa_skill_invocation_name")}
                />

                <Title mb="sm" mt="xl" size="h4">{t("Teams")}</Title>
                <Select
                    mt="xs"
                    label={t("Assigned team")}
                    placeholder={t("Select team")}
                    data={userTeams}
                    allowDeselect={true}
                    disabled={project.team !== null}
                    {...form.getInputProps("team_id")}
                />

                <Button type="submit" mt="xl" loading={updateLoading}>
                    {t("Save")}
                </Button>
            </form>

            {user?.is_admin &&
                <>
                    <Title mb="sm" mt="xl" size="h5">{t("Admin")}</Title>
                    <TextInput
                        label={t("Rasa service URL")}
                        defaultValue={project.rasa_service_url}
                        disabled={true}
                        rightSection={<CopyButton value={project.rasa_service_url} />}
                        contentEditable={false}
                    />
                    {project.rasa_service_url == null &&
                        <Button loading={rebuildLoading} onClick={rebuild} mt="sm" leftSection={<IconRefresh size={16} />}>{t("Rebuild")}</Button>
                    }

                </>
            }

            <Divider mt="xl" mb="xl" />
            <Flex>
                <Button fullWidth color="red" leftSection={<IconTrash size={16} />} onClick={openDeleteModal} loading={deleteLoading}>
                    {t("Delete project")}
                </Button>
            </Flex>
        </>
    )
}

export default ProjectSettingsCustom
